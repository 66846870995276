import './bootstrap-custom';
import 'bootstrap-datepicker';
import './jquery.lazyload';
import './wistia';

// Initial integrations setup
$(function () {
  $("img").lazyload();
  $(".datepicker").datepicker({
    format: 'yyyy-mm-dd',
    autoclose: true,
    orientation: 'bottom auto',
    todayHighlight: true,
    startDate: new Date()
  });

  $(".simple-datepicker").datepicker({
    format: 'yyyy-mm-dd',
    autoclose: true,
    orientation: 'bottom auto',
    todayHighlight: true
  });

  $('.lazy-modal').on("show.bs.modal", function (e) {
    $(this).find('img').each(function () {
      const dataOriginal = $(this).attr('data-original');
      $(this).attr('src', dataOriginal);
    });
  });
});
