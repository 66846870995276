const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
});

const updateCheckoutTotals = () => {
  const packageIds = new Set();
  let total = 0;
  let deposit = 0;
  let late = 0;
  let savings = 0;
  Array.from(document.getElementsByClassName('product-element')).forEach((element) => {
    if (!element.classList.contains('d-none')) {
      if (element.getAttribute('data-package-id')) packageIds.add(element.getAttribute('data-package-id'));
      const product = products.find((p) => String(p.id) === element.getAttribute('data-product-id'));
      const qty = Number(element.getElementsByClassName('qty-input')[0].value);
      const feeTotal = product.fee * qty;
      element.getElementsByClassName('qty-total')[0].innerText = currencyFormatter.format(feeTotal);
      total += feeTotal;
      deposit += product.deposit * qty;
      late += product.late * qty;
      $(`.term-item-qty-${element.getAttribute('data-id')}`).text(qty);
    } else {
      $(`.term-item-qty-${element.getAttribute('data-id')}`).parent().addClass('d-none');
    }
  });

  packageIds.forEach((packageId) => {
    const item = packages.find((p) => String(p.id) === packageId);
    savings += item.savings;
  });

  if (savings) {
    $('.checkout-savings').text(`(${currencyFormatter.format(savings)})`);
  } else {
    $('.checkout-savings').parent().prev().addClass('d-none');
    $('.checkout-savings').parent().addClass('d-none');
  }

	$('.checkout-total').text(currencyFormatter.format(total - savings));
	$('.checkout-deposit').text(currencyFormatter.format(deposit));
	$('.checkout-balance').text(currencyFormatter.format(total - deposit - savings));
	$('.checkout-late-fee').text(currencyFormatter.format(late));
  $('.partial-order-update-input').trigger('blur');
};

$(function () {
  $('.product-remove').on('click', function (e) {
    e.preventDefault();

    const form = this.parentElement.parentElement;
    const packageId = form.getAttribute('data-package-id');

    if (packageId) {
      document.querySelectorAll(`[data-package-id="${packageId}"]`).forEach((packageForm) => {
        const item = $(packageForm.getElementsByClassName('qty-input')[0]);
        packageForm.classList.add('d-none');
        const inputName = item.attr('name').replace('qty', '_destroy');
        item.after(`<input value="1" type="hidden" name="${inputName}">`);
      });
    } else {
      const item = $(form.getElementsByClassName('qty-input')[0]);
      form.classList.add('d-none');
      const inputName = item.attr('name').replace('qty', '_destroy');
      item.after(`<input value="1" type="hidden" name="${inputName}">`);

      // Ensure there's a min of 2 marquee letters
      const remainingMarquees = document.querySelectorAll(`[data-category-id="${marqueeCategoryId}"]:not(.d-none)`);

      if (remainingMarquees.length === 1) {
        remainingMarquees.forEach((form) => {
          const item = $(form.getElementsByClassName('qty-input')[0]);
          form.classList.add('d-none');
          const inputName = item.attr('name').replace('qty', '_destroy');
          item.after(`<input value="1" type="hidden" name="${inputName}">`);
        });
      }
    }

    updateCheckoutTotals();
  });

  $('.qty-input').on('change', updateCheckoutTotals);
});
