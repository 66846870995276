const submitMarquee = (parent) => {
  const submit = parent.find('.marquee-submit');
  submit.click();
};

const handleMarqueeClick = (parent) => {
  const notAvailable = [];
  const search = parent.find('.marquee-search');
  const marqueeList = search.val().toUpperCase().split('');
  const marquees = marqueeList.filter((m) => m !== ' ');
  marqueeList.forEach((marquee) => {
    const product = marqueeProducts.find((product) => product === marquee);
    if (marquee !== ' ' && !product) notAvailable.push(marquee);
  });

  if (notAvailable.length) {
    parent.find('.plr-marquee-error').removeClass('d-none');
    parent.find('.plr-marquee-error').html(`We do not have the following character(s): ${notAvailable.join(' ')}`);
  } else if (!parent.find('.datepicker').length) {
    submitMarquee(parent);
  } else if (marquees.length < 2) {
    parent.find('.plr-marquee-error').removeClass('d-none');
    parent.find('.plr-marquee-error').html('Please add at least 2 Marquee Letters');
  } else {
    parent.find('.plr-marquee-error').addClass('d-none');
    const initialDate = parent.find('.datepicker').val();
    const parts = initialDate.split('-');
    const date = new Date(parts[0], parts[1] - 1, parts[2]);
    const cutOffDate = new Date();
    cutOffDate.setDate(cutOffDate.getDate() + 8);
    cutOffDate.setHours(23, 59, 59, 999);
    if (date <= cutOffDate) {
      $('.plr-marquee-error')
      parent.find('.marquee-form').addClass('d-none');
      const form = parent.find('.marquee-message-form');
      $('.plr-marquee-help').addClass('d-none');
      form.removeClass('d-none');
      form.find('.marquee-message-event-date').val(initialDate);
      const message = form.find('.marquee-message');
      const page = form.find('.marquee-message-page');
      const disclaimerContainer = form.find('.marquee-message-form-price-disclaimer-container');
      const disclaimer = form.find('.marquee-message-form-price-disclaimer');
      const searchList = marquees.join(', ');
      message.val(`${message.val()} ${initialDate}: ${searchList}`)
      page.val(`${page.val()}#${search.val()}`);
      disclaimerContainer.removeClass('d-none');
      disclaimer.text(`Standard price for these ${marquees.length} items is $${marquees.length * 49}. Due to the short timeline, express shipping costs may also be required.`);
    } else {
      submitMarquee(parent)
    }
  }
};

$(function () {
  // Confetti page handler
  $('#confetti-carousel').on('slide.bs.carousel', function (e) {
    const image = $(e.relatedTarget).find('img');
    image.attr('src', image.attr('data-original'));
  });

  const handleChange = () => {
    const findProduct = (id) => products.find((product) => product.id === parseInt(productMapping[id]));
    const findProductIndex = (id) => products.findIndex((product) => product.id === parseInt(productMapping[id]));
    const product = findProduct($('.product_dropdown').val());
    const productIndex = findProductIndex($('.product_dropdown').val());
    $('.product_qty').attr('placeholder', `minimum is ${product.min}`);
    $('.product_img').attr('src', product.url);
    $('.product_fee').html(product.fee);
    $(`.description${product.id}`).removeClass('d-none');
    $(`.tech-specs${product.id}`).removeClass('d-none');
    const image = $(`.image${product.id}`).find('img');
    image.attr('src', image.attr('data-original'));
    $('.carousel').carousel(productIndex);
    products.forEach((p) => {
      if (product.id !== p.id) {
        $(`.description${p.id}`).addClass('d-none');
        $(`.tech-specs${p.id}`).addClass('d-none');
      }
    });
  };

  $(".product_dropdown").change(handleChange);

  $('.confetti-img').one('load', function () {
    const findProductSlug = (slug) => products.find((product) => product.slug === slug);
    const product = findProductSlug(window.location.hash.replace('#', ''));
    if (product) {
      const key = Object.entries(productMapping).find(([_k, value]) => value === String(product.id));
      $('.product_dropdown').val(key[0]);
      handleChange();
      setTimeout(() => $('html,body').animate({ scrollTop: $('.confetti-card').offset().top }, 'slow'), 500);
    }
  });

  $('.marquee-btn').click(function (e) {
    e.preventDefault();
    const parent = $(this).parents('.marquee-container');
    if ($(this).parents('form')[0].reportValidity()) handleMarqueeClick(parent);
  })
});
