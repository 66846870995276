let designSelected = false;
let calc_lights = 0;
let calc_low_qty = 0;
let calc_mid_qty = 0;
let calc_high_qty = 0;

$(function () {
  $('#area').on('submit', calculate_by_area);
  $('#sq_feet').on('submit', calculate_by_square_feet);

  function sq_feet_to_calc_lights(sq_feet) {
    x = Math.round(sq_feet / 100);
    if (x <= 7) {
      calc_lights = x + 1;
    } else if (7 <= x && x <= 14) {
      calc_lights = x + 2;
    } else if (14 <= x && x <= 21) {
      calc_lights = x + 3;
    } else {
      calc_lights = x;
    }

    calc_low_qty = Math.round(calc_lights / 2);
    calc_low_qty = calc_minimum_qty > calc_low_qty ? calc_minimum_qty : calc_low_qty;
    low_cost = calc_cost * calc_low_qty;
    calc_high_qty = Math.round(calc_lights * 1.5);
    calc_high_qty = calc_minimum_qty >= calc_high_qty ? parseInt(calc_minimum_qty) + 2 : calc_high_qty;
    high_cost = calc_cost * calc_high_qty;
    calc_mid_qty = 2 * Math.round(calc_lights / 2);
    calc_mid_qty = calc_minimum_qty >= calc_mid_qty ? parseInt(calc_minimum_qty) + 1 : calc_mid_qty;
    mid_cost = calc_mid_qty * calc_cost;

    $('.results_area').html(`
      <br>Recommended quantity range: <br> ${calc_low_qty} - ${calc_high_qty} units <br>
      <br> Price range @ ${calc_fee} each: <br> $${low_cost} - $${high_cost}
      <br><br> Average for this size space: <br> ${calc_mid_qty} units ($${mid_cost})
    `);
  }

  function calculate_by_square_feet(event) {
    event.preventDefault();
    var sq_feet = parseInt(document.getElementById('sqft').value) || 0;
    sq_feet_to_calc_lights(sq_feet);
  }

  function calculate_by_area(event) {
    event.preventDefault();
    var width = parseInt(document.getElementById('width').value) || 0;
    var length = parseInt(document.getElementById('length').value) || 0;
    var sq_feet = width * length;
    $('.results_feet').html(`
      Area dimensions: ${width} x ${length}<br>
      Square Footage: ${sq_feet} <br>
    `);
    sq_feet_to_calc_lights(sq_feet);
  }

  $('.cut-date-form').on('submit', function (e) {
    e.preventDefault();
    const input = $(this).find('input[name="message[state]"]');
    if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input.val())) {
      this.submit();
    } else {
      input[0].setCustomValidity('Invalid Zip Code');
    }
  });

  $('.custom-validty-input').on('change', function (e) {
    this.setCustomValidity('');
  });

  $('.design-form').on('submit', function (e) {
    if (!designSelected) {
      e.preventDefault();
      const modal = $('#designModal');
      modal.modal('toggle');
    } else if (!this.checkValidity()) {
      e.preventDefault();
    }
  });

  $('.design-button').on('click', function (e) {
    e.preventDefault();
    designSelected = true;

    // Close the selection modal
    const modal = $('#designModal');
    modal.modal('toggle');

    // Update the modal button
    const designModalButton = document.getElementsByClassName('design-modal-button')[0];
    designModalButton.classList.remove('btn-warning');
    designModalButton.classList.add('btn-info');

    const designId = this.getAttribute('data-design');
    const designsContainer = document.getElementsByClassName('designs-container')[0];
    const target = designsContainer.getAttribute('data-target');

    // Add the needed inputs
    if (designId !== 'none') {
      designsContainer.innerHTML = '';
      const inputs = document.createElement('div');
      const prefix = target === 'Reservation' ? 'reservation[booked_items_attributes][0]' : 'booked_item';
      const designLines = designs[designId].map((d, i) => `
        <input type="text" class="form-control mb-2" name="${prefix}[booked_design_attributes][booked_design_lines_attributes][${i}][custom_text]" placeholder='${d.placeholderText}' required>
        <input type="hidden" name="${prefix}[booked_design_attributes][booked_design_lines_attributes][${i}][line_number]" value='${d.lineNumber}'>
        <input type="hidden" name="${prefix}[booked_design_attributes][booked_design_lines_attributes][${i}][design_id]" value='${d.designId}'>
      `)
      inputs.innerHTML = `
        <label>Enter your custom text here:</label>
        <input type="hidden" name="${prefix}[booked_design_attributes][design_id]" value='${designId}'>
        ${designLines.join('')}
      `;
      designsContainer.append(inputs);

      designModalButton.innerHTML = `Design #${designId} Selected`;
    } else {
      designModalButton.innerHTML = 'No Design Needed';
    }

    // Enable the submit button
    designsContainer.parentElement.getElementsByClassName('btn')[0].removeAttribute('disabled');
  });
});
